<template>
  <div class="page">
    <header>
      <div class="logo">
        TodoListView
      </div>

      <nav>
        <a href="#home">Home</a>
        <a href="#product">How</a>
        <a href="#privacy">Privacy Policy</a>
        <a href="#about">About us</a>
      </nav>
    </header>
    <div class="home" id="home">
      <h4>Schedule planning tool</h4>
      <p>
        Time is one of the most valuable assets a person can have. TimeTable, a scheduling tool, helps you manage your work and your life so that you can do more with less.
      </p>
    </div>

    <div id="product">
      <div class="company">
        <div class="companylist">
          <div class="companyitem">
            <div class="right">
              <img src="../assets/images/item1.png" alt="" />
            </div>
            <div class="left">
              <div class="msg">
                <h3>A great choice for successful people</h3>
                <span>Successful people's schedules are always full and without the help of tools time may not be optimally organised. Choose a time management tool for you or your secretary so that you can spend the same amount of time making twice as much money!</span>
              </div>
            </div>
          </div>
          <div class="companyitem">
            <div class="left">
              <div class="msg">
                <h3>Time planning to help you succeed</h3>
                <span>Students, teachers and managers all need time planning, managing your time is managing your life</span>
              </div>
            </div>
            <div class="right">
              <img src="../assets/images/item2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="privacy" id="privacy">
      <div class="title">Privacy Policy</div>
      <div class="privacy-text">
        <br /> Welcome to TodoListView, we take privacy very seriously and this policy is designed to share the information we collect and how we use it.

        <h3>1.Scope of application of this Privacy Policy</h3>
        <br /> This policy applies to all personal data collected in connection with our services, regardless of the region in which our services are used.

        <h3>2.Personal data to be collected</h3>
        <br /> When you use our services or applications, we will not actively collect any of your personal information, including your name, age, ID number, mobile phone number, etc. We also do not collect your device information, such as your device type, IP address, phone storage space, videos or pictures, etc. If you voluntarily provide us with some of your personal information, we will protect it through reasonable protection measures to ensure the security of your personal information.

        <h3>3.Use of Information</h3>
        <br /> We will not provide, sell, rent, share or trade your personal information to any unrelated third parties, unless we obtain your prior permission, and all the information we collect will only be used to provide personalized services.

        <h3> 4.Changes to Privacy Policy</h3>
        <br /> We may revise this Privacy Policy from time to time, but we will post it prominently within this Privacy Policy. In this case, if you continue to use our services, you agree to be bound by the revised Privacy Policy.

        <h3>5.Contact us</h3>
        <br /> If you have any suggestions for us, you can contact us through the following email: 21591332@qq.com.

      </div>
    </div>

    <footer id="about">
      <p class="title">About us</p>

      <div class="contact">
        <div class="item">
          <p>
            <img style="width: 1.3rem;height: 1.0rem;" src="../assets/images/email.png" alt="" /><span>Email: 21591332@qq.com</span>
          </p>
        </div>
        <!-- <div class="item" style="margin-top:-3rem;">
          <p>
            <img style="width: 1.2rem;height: 1.6rem;float: left;" src="../assets/images/address.png" alt="" /><span>Address: NEO SOHO RESIDENCES 3210 JL S PARMAN KAV 28, Kel. Tanjung Duren Selatan, Kec. Grogol Petamburan, Kota Adm. Jakarta Barat, Provinsi DKI Jakarta</span>
          </p>
        </div> -->
      </div>
      <p class="end">
        Copyright 2022. All rights reserved by TodoListView.
      </p>
    </footer>
  </div>
</template>
<style lang="less">
a {
  text-decoration: none;
}

header {
  width: 100%;
  height: 80px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #2b62ff;
  z-index: 1000000000000;

  .logo {
    margin-left: 390px;
    float: left;
    font-size: 24px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #ffffff;
    line-height: 80px;
    .logoname {
      font-family: Helvetica-Bold;
      font-size: 14px;
      color: #333333;
      float: right;
      margin-left: 20px;
    }
  }

  nav {
    height: 80px;
    float: right;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-right: 390px;

    a {
      width: 150px;
      height: 50px;
      line-height: 50px;
      border-radius: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fff;
    }

    a:hover {
      background: #2b62ff;
      border-radius: 25px;
      color: #fff;
      transition: 0.3s;
    }
  }
}

.home {
  background: url(../assets/images/banner.png) no-repeat;
  background-size: 100%;
  width: 100%;
  height: 700px;
  overflow: hidden;
  margin-top: 70px;
  h4 {
    font-size: 48px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #ffffff;
    margin-left: 390px;
    margin-top: 200px;
    width: 400px;
  }
  p {
    width: 400px;
    font-size: 18px;
    font-family: ArialMT;
    color: #ffffff;
    margin-left: 390px;
    margin-top: 20px;
    line-height: 24px;
  }
}

.product {
  .productlist {
    padding-left: 170px;
    overflow: hidden;

    .productitem {
      width: 540px;
      margin: 40px 120px;
      float: left;
      height: 300px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0 15px 30px 0 rgba(220, 220, 220, 0.3);
      border-radius: 30px;

      img {
        width: 180px;
        height: 180px;
      }

      .itemmsg {
        font-family: Helvetica;
        font-size: 20px;
        color: #333333;
        line-height: 40px;
        margin-top: 30px;
      }
    }
  }
}

.company {
  padding: 0 390px;
  width: 100%;
  height: 1400px;
  background: url(../assets/images/companyBg.png) no-repeat 100%;
  background-size: 100%;

  .companylist {
    margin-top: 100px;

    .companyitem {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 400px;
      margin-bottom: 300px;

      .left {
        .msg {
          max-width: 540px;

          h3 {
            width: 500px;
            font-size: 48px;
            font-family: Arial-Black, Arial;
            font-weight: 900;
            color: #213d52;
            line-height: 68px;
            margin-bottom: 20px;
          }

          span {
            width: 440px;
            height: 99px;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #5d707e;
            line-height: 33px;
          }
        }
      }

      .right {
        img {
          width: 499px;
          height: 420px;
        }
      }
      .right:nth-child(2) {
        img {
          width: 576px;
          height: 394px;
        }
      }
    }
  }
}
.privacy {
  padding: 100px 390px;
  .title {
    font-weight: bold;
    font-size: 38px;
    text-align: center;
  }
  .privacy-text {
    font-size: 20px;
    h3 {
      font-weight: bold;
    }
  }
}

.about {
  width: 1920px;
  overflow: hidden;

  .aboutimg {
    /* background: url(../assets/images/about.png) no-repeat 100%; */
    background-size: 100%;
    width: 100%;
    overflow: hidden;
    height: 510px;

    p {
      text-align: center;
      font-size: 48px;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #213d52;
      margin: 95px 0;
    }
  }

  .office {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -255px;

    img {
      width: 350px;
      height: 490px;
      margin: 0 15px;
    }
  }

  .title {
    color: #fff;
    padding: 20px 0 60px 0;
    margin-top: 90px;
    width: 100%;
  }

  .msg {
    width: 1114px;
    height: 238px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5d707e;
    line-height: 33px;
    margin-left: 405px;
    margin-top: 70px;
  }
}

footer {
  overflow: hidden;
  background: #2f2f2f;
  color: #fff;
  padding: 0 370px;

  .end {
    font-size: 14px;
    font-family: Helvetica;
    color: #ffffff;
    line-height: 17px;
    text-align: center;
    margin: 40px 0;
  }

  .title {
    font-size: 24px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    line-height: 29px;
    text-align: center;
    margin: 70px 0;
  }

  .msg {
    font-family: Helvetica;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 100px;
    padding-bottom: 60px;
    border-bottom: 1px dashed #979797;
  }

  .contact {
    width: 100%;
    margin: 70px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    color: #ffffff;
    text-align: left;

    .item {
      width: 300px;

      p {
        width: 400px;
        height: 62px;

        span {
          font-size: 16px;
          font-family: Helvetica;
          color: #ffffff;
          line-height: 31px;
        }

        img {
          width: 30px;
          height: 30px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
<script>
export default {
  name: "Home",
  data () {
    return {
      showmore: true,
    };
  },
  methods: {
    goGp () {
      window.open(
        "https://play.google.com/store/apps/details?id=com.my.sn.sticky.notes.msnycdw"
      );
    },
  },
};
</script>
